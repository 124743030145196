<template>
  <b-container class="bg-light page-wrapper sandbox-currency-input-v3-example">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Currency input demo (version 3.0.4 or higher)</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <currency-input
            v-model="money"
            input-class="rounded-pill"
            :state="state"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <p>Controlled our value: {{ money }}</p>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="text-center">
          <button class="my-4 mx-2 btn btn-primary" @click="validateTrue">Validate as VALID</button>
          <button class="my-4 mx-2 btn btn-secondary" @click="validateFalse">Validate as INVALID</button>
          <button class="my-4 mx-2 btn btn-primary" @click="clearValidation">Clear validation</button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import CurrencyInput from '@/components/common/CurrencyInput'

export default {
  name: 'CurrencyInputExample',
  components: { CurrencyInput },
  data () {
    return {
      money: 250,
      state: null
    }
  },
  methods: {
    validateTrue () {
      this.state = true
    },
    validateFalse () {
      this.state = false
    },
    clearValidation () {
      this.state = null
    }
  }
}
</script>
